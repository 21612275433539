import { useState } from "react"
import axios from 'axios'
import styles from '../../style';
import { DropdownSearch } from "../../components";
import Swal from "sweetalert2";

const AddParcelOut = () => {

    const [input, setInput] = useState({
        receiver: '',
        sender: '',
        tracknumberLine: '',
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const eid = e.target.eid;
        setInput((values) => ({ ...values, [name]: value, [name+"Eid"]: eid }));
    };

    function handleClick(name, value, eid) {
        setInput((values) => ({ ...values, [name]: value, [name+"Eid"]: eid }));
    };

    const [userInfo, setUserInfo] = useState([])

    const getEmpInformation = async (e) => {
        e.preventDefault();
        // try {
        //     const response = await axios.post(
        //         `${process.env.REACT_APP_API_BASE_URL}/employees/get-emp-out`,
        //         { input },
        //         { headers: { 'Content-Type': 'application/json' } }
        //     );
        //     const jsonData = response.data;
        //     if (jsonData.length < 1) {
        //         alert('incorrect employee name or employee id!');
        //     } else if (input?.parcelType == null) {
        //         alert('please select parcel type.')
        //     } else {
        //         setUserInfo(jsonData);
        //         handlePopupToggle();
        //     }
        // } catch (error) {
        //     console.error('Error can\'t get data: ', error);
        // }
        fetch(`${process.env.REACT_APP_API_BASE_URL}/employees/get-emp-out`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),

        }).then(response => response.json())
            .then(data => {
                if (data.length < 1) {
                    // alert('รหัสพนักงานหรือชื่อพนักงานไม่มีอยู่ในระบบ!')
                    Swal.fire({
                        icon: 'warning',
                        title: 'รหัสพนักงานหรือชื่อพนักงานไม่มีอยู่ในระบบ!',
                        confirmButtonText: 'ตกลง',
                        confirmButtonColor: '#3085d6',
                      }); 
                } else if (input?.parcelType == null) {
                    // alert('กรุณาเลือกประเภทพัสดุ')
                    Swal.fire({
                        icon: 'warning',
                        title: 'กรุณาเลือกประเภทพัสดุ',
                        confirmButtonText: 'ตกลง',
                        confirmButtonColor: '#3085d6',
                      }); 
                } else {
                    setUserInfo(data)
                    handlePopupToggle()
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const [isPopupVisible, setPopupVisibility] = useState(false);

    const handlePopupToggle = () => {
        setPopupVisibility(!isPopupVisible);
    };

    // const handlePopupToggleSave = () => {
    //     setPopupVisibility(!isPopupVisible);
    //     handleCreateParcel();
    // };

    const handleCreateParcel = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/parcels/create-parcel-admin-out`,
            { userInfo });
            // alert("บันทึกข้อมูลสำเร็จ!");
            // window.location.reload();
            Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลสำเร็จ!',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
              }); 
            console.log('Data inserted successfully');
        } catch (error) {
            // alert("บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            Swal.fire({
                icon: 'error',
                title: 'บันทึกข้อมูลไม่สำเร็จ',
                text: 'กรุณาลองใหม่อีกครั้ง',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }); 
            console.error('Error inserting data:', error);
        }
    };

    const handleKeyDown = (e) => {
        const allowedKeys = /[a-zA-Z0-9]/;
        console.log(e.key)
        if (!allowedKeys.test(e.key)) {
            console.log(e)
            e.preventDefault();
        }
    };

    return (
        <div>
            <p className={`${styles.pageHeading} text-orangeSKC`}>บันทึกข้อมูลพัสดุรับจากภายนอกหน่วยงาน</p>
            <div className={`${styles.adminPageBox} bg-white mt-12`}>
                <form onSubmit={getEmpInformation}>

                    <div className={`${styles.cardContainer}`}>
                        <div className={`${styles.adminGrids} ${styles.adminPageBox}`}>

                            <div className="">
                                <label className="label">
                                    <span className="label-text">ชื่อ<h className='font-bold'>ผู้ส่ง</h>พัสดุ</span>
                                </label>
                                <input type="text" name="sender" value={input.sender || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" required onChange={handleChange} />
                            </div>

                            <div className="">
                                <label className="label">
                                    <span className="label-text">ชื่อ<h className='font-bold'>ผู้รับ</h>พัสดุ หรือรหัสพนักงาน</span>
                                </label>
                                <input type="text" name="receiver" value={input.receiver || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" required onChange={handleChange} />
                                <DropdownSearch searchTerm={input.receiver} handleClick={handleClick} name={'receiver'} />
                            </div>

                            <div className="">
                                <label className="label">
                                    <span className="label-text">ประเภทพัสดุ</span>
                                </label>
                                <select name="parcelType" value={input.parcelType || ''} className="input input-bordered w-full" onChange={handleChange}>
                                    <option value="" disabled>กรุณาเลือก</option>
                                    <option value="T02">ซอง</option>
                                    <option value="T01">กล่อง</option>
                                </select>
                            </div>

                            <div className="">
                                <label className="label">
                                    <span className="label-text">หมายเลขติดตามพัสดุ</span>
                                </label>
                                <textarea name="tracknumberLine" value={input.tracknumberLine || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" required onChange={handleChange} onKeyDown={handleKeyDown}/>
                            </div >

                        </div>
                    </div>

                    <input type="submit" value={"บันทึกข้อมูล"} className={`${styles.adminPageBtn} cursor-pointer rounded-md hover:bg-hoverBackground w-full`} />

                </form>

                {isPopupVisible && (
                    <div id='container' className='fixed z-20 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
                        <div className='bg-white py-5 sm:px-12 px-8 rounded-xl'>
                            <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">ยืนยันข้อมูล</h3>

                            <div className="flex">
                                <div className='mb-3 mr-5 flex-start border-2 rounded-md p-5'>
                                    <h1 className={`${styles.popupTextHead}`}>ข้อมูลผู้ส่งพัสดุ</h1>
                                    <p className={`${styles.popupTextBody}`}>ชื่อ-นามสกุล: <label className="font-bold">{userInfo[0].senderName}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ประเภทพัสดุ: <label className="font-bold">{userInfo[0].type}</label></p>
                                    {userInfo[0].trackNumber.map((tracknum, index) => (
                                        <p className={`${styles.popupTextBody}`}>หมายเลขติดตามพัสดุ {index + 1}:
                                            <label className="font-bold"> {tracknum}</label></p>))}
                                </div>

                                <div className='mb-3 flex-end border-2 rounded-md p-5'>
                                    <h1 className={`${styles.popupTextHead}`}>ข้อมูลผู้รับพัสดุ</h1>
                                    <p className={`${styles.popupTextBody}`}>รหัสพนักงาน: <label className="font-bold">{userInfo[0].eid}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ชื่อ-นามสกุล: <label className="font-bold">{userInfo[0].name} {userInfo[0].lastname}</label></p>
                                    <p className={`${styles.popupTextBody}`}>อีเมล: <label className="font-bold">{userInfo[0].email}</label></p>
                                    <p className={`${styles.popupTextBody}`}>สังกัดหน่วยงาน: <label className="font-bold">{userInfo[0].section}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ฝ่าย: <label className="font-bold">{userInfo[0].department}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ส่วน: <label className="font-bold">{userInfo[0].division}</label></p>
                                    <p className={`${styles.popupTextBody}`}>โรงงานปลายทาง: <label className="font-bold">{userInfo[0].workArea}</label></p>
                                </div>


                            </div>

                            <p className={`${styles.popupTextBody} flex justify-end`}>เพิ่มพัสดุทั้งหมด <label className="font-bold"> {userInfo[0].trackNumber.length} </label> รายการ</p>

                            <div className='flex justify-end'>
                                <button className={`${styles.confirmBtn}`} onClick={() => { setPopupVisibility(false); handleCreateParcel();}}>ยืนยัน</button>
                                <button className={`${styles.cancelBtn} `} onClick={() => {setPopupVisibility(false)}}>ยกเลิก</button>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AddParcelOut